@import url('./stepper-styles.less');
@import url('./snackbar.less');

.common-qtm-design-textarea-css,
.common-qtm-design-input-css,
.common-qtm-design-select-css{
    height: var(--input-medium-height);
    font-size: var(--input-medium-font-size);
    line-height: var(--input-medium-line-height);
    padding-right: var(--input-medium-padding-right);
    padding-top: var(--input-medium-padding-top);
    padding-bottom: var(--input-medium-padding-bottom);
    padding-left: var(--input-medium-padding-left);
    width: 100%;
    font-family: var(--input-font-family);
    font-weight: var(--input-font-weight);
    background-color: var(--input-background-color);
    border-width: var(--input-border-width);
    border-style: var(--input-border-style);
    border-color: var(--input-border-color);
    border-radius: var(--input-border-radius);
    color: var(--input-text-color);
}

.common-qtm-design-input-css:hover:not(:disabled),
.common-qtm-design-select-css:hover:not(:disabled) {
    background: var(--input-hover-background-color);
}
.common-qtm-design-textarea-css:focus:not(:disabled) ,
.common-qtm-design-input-css:focus:not(:disabled) ,
.common-qtm-design-select-css:focus:not(:disabled) {
    border-color: var(--input-focus-border-color);
}

.common-qtm-design-textarea-css.error,
.common-qtm-design-input-css.error ,
.common-qtm-design-select-css.error {
    border-color: var(--input-error-border-color);
}

.common-qtm-design-textarea-css:focus,
.common-qtm-design-input-css:focus,
.common-qtm-design-select-css:focus {
    outline: none;
}

.common-qtm-design-textarea-css:disabled,
.common-qtm-design-input-css:disabled,
.common-qtm-design-select-css:disabled {
    border-color: var(--input-disabled-border-color);
    background-color: var(--input-disabled-background-color);
    cursor: not-allowed;
}
 
.common-qtm-design-textarea-css{
    min-height: 200px;
    width: 100%;
    resize: none;
}

@media (max-width: 360px) {
    .container{
        max-width: 100%!important;
    }
}


.qtm-dropdown.qtm-visible .qtm-dropdown-overlay {
    display: block;
    z-index: 2;
    position: relative;
}

.input-error.common-qtm-design-input-css:focus:not(:disabled), 
.input-error.common-qtm-design-select-css:focus:not(:disabled),
.input-error{
    color: red;
    border-color: red;
}