.info {
    background: #2196F3;
}

.error{
    background: #F44336;
}

.warning  {
    background: #d77400;
}

.mat-simple-snackbar-action {
    color: #fff;
    border: 1px solid #fff;
    border-radius: 3px; 
}
.mat-simple-snack-bar-content{ 
    white-space: pre-wrap
}

@media(min-width:991px){
    .cdk-overlay-container .mat-snack-bar-container{
        max-width: 735px
    } 
}

@media(max-width:990px){
    .cdk-overlay-container .mat-snack-bar-container{
        max-width: 90vw
    } 
}
