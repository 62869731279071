@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    overflow-x:hidden;
}
html, body{
    background-color: var(--palettes-bluegrey-25, #F7F7F9);
}

body {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;   
}
  
.custom-header-shadow .qtm-header{
    box-shadow: 0px 2px 4px rgb(1 5 50 / 14%), 0px 4px 5px rgb(1 5 50 / 12%), 0px 1px 10px rgb(1 5 50 / 20%);
    position: relative;
    z-index: 9;
}

.login .qtm-header{
    box-shadow: 0px 2px 4px rgb(1 5 50 / 14%), 0px 4px 5px rgb(1 5 50 / 12%), 0px 1px 10px rgb(1 5 50 / 20%);
    z-index: 9;
    position: relative;
}

.qtm-dropdown,
.qtm-form-field{
    width: 100%;
    display: inline-block;
}
.row-direction .qtm-radio-group{
    flex-direction: row;
    width: 100%;
} 

.qtm-form-field .font-bold .qtm-form-label.qtm-medium{
    font-weight: var(--font-weight-bold) !important;
}

.absolute-options .qtm-dropdown.qtm-visible .qtm-dropdown-overlay{
    position: absolute!important;
    z-index: 9!important
}
 
.tooltip-icon{
    color: var(--button-filled-primary-background-color);
    cursor: pointer;
}
.qtm-tooltip.qtm-right .qtm-tooltip-container{
    z-index: 9;
}

.qtm-tooltip.qtm-top .qtm-tooltip-container{
    z-index: 9;
    max-width: 400px;
}

.qtm-dropdown-overlay{
    min-width: 100%!important;
} 

.qtm-button{
    width: inherit;
}

.mat-icon-button{
    display: flex;
    align-items: center;
    justify-content: center;
}

.mat-form-field-infix{
    padding: 0!important;
    margin: 0!important;
    border-top: 0!important;
}


.mat-select-disabled{
    background-color: var(--input-disabled-background-color)!important;
    border-color: var(--input-disabled-border-color)!important;
}

.mat-form-field-underline{
    display: none;
}


/* sidebar */

.mat-drawer {
    width: 15rem!important;
}

.mat-drawer-content {
    background: #F6F6FF;
    /* height: calc(100vh - (64px + 80px))!important; */
}

.mat-drawer.custom-drawer {
    height: calc(100vh - 64px);
    bottom: 0;
    top: auto;
    position: fixed;
}

.mat-drawer {
    width: 15rem!important;
}
.mat-drawer-backdrop{
    position: fixed!important;
}

.mat-drawer .qtm-menu-item-list .qtm-submenu-item{
    padding-left:16px;
}

.mat-drawer .qtm-menu-item-list .qtm-submenu-item .qtm-icon{
    width: 24px;
    height: 24px;
    font-size: 24px;
    margin-right: 8px;
}
.mat-drawer .qtm-menu-item-list .qtm-submenu-item .qtm-icon .material-icons{
    font-family: 'Material Icons Outlined';
}

.mat-drawer .qtm-menu-item-list .qtm-submenu-item.qtm-active .material-icons{
    color: var(--itemlist-item-active-label-color);
}
.mat-drawer qtm-submenu-item.active .qtm-active a,
.mat-drawer .home-active,
.mat-drawer .qtm-menu-item-list .qtm-submenu-item.qtm-active a{
    font-weight: 600;
    color: var(--itemlist-item-active-label-color);
}

.mat-drawer-container .qtm-menu-item-list{
    width: 100%;
}
.mat-drawer{
    width: var(--drawer-width);
} 
 
.qtm-active .active{
    color: var(--itemlist-item-active-label-color);
}

.unavailable-link a,
.unavailable-link .material-icons{
    color: #c6c8d5;
    cursor: not-allowed;
}

.error .textarea-highlighter{
  border-color: var(--input-error-border-color);
}

mark {
    border-radius: 0;
    color: transparent;
    border-bottom: 3px solid red;
    background-color: transparent;
    position: relative;
    left: -1px;
    top: -3px;
}

.cdk-global-scrollblock{
    top: 0!important;
    overflow: auto;
}

.qtm-toggle-switch > span{
    order: 1;
}
