.mat-stepper-label-position-bottom .mat-horizontal-stepper-header {
    background-color: transparent !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
    padding: 24px 0;
}

.mat-horizontal-stepper-header-container {
    margin: 0 auto;
    padding: 0 20px; 
    
    @media (min-width: 360px) {
        max-width: 360px
    }

    @media (min-width: 640px) {
        max-width: 640px
    }

    @media (min-width: 768px) {
        max-width: 768px
    }

    @media (min-width: 992px) {
        max-width: 992px
    }

    @media (min-width: 1280px) {
        max-width: 1320px
    }
    .mat-step-icon {
        width: 30px;
        height: 30px;
    }

    .mat-step-header {
        .mat-step-icon {
            border: 2px solid #bcbed0;
            background-color: #fff;
            color: #bcbed0;
        }

        .mat-step-icon-state-edit {
            background-color: #aeabd6;
            color: #fff;
            border-color: #aeabd6;
        }

        .mat-step-icon-selected {
            background-color: #0c039b;
            color: #fff;
            border-color: #0c039b;
        }

        .mat-step-icon-state-error {
            border-color: #f44336;

            .mat-icon {
                color: #f44336;
            }
        }
    }

    .mat-step-label-error .mat-step-text-label {
        color: #f44336;
    }

    .mat-step-text-label {
        color: #bcbed0;
    }

    .mat-step-label-selected {
        .mat-step-text-label {
            font-weight: 600;
            color: #000;
        }
    }
}

.mat-stepper-horizontal .mat-horizontal-content-container {
    overflow: visible !important;
}

.stepper-action-bar {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 80px;
    background: var(--palettes-bluegrey-25, #F7F7F9);
    display: flex;
    justify-content: space-between; 
    z-index: 4;
    padding: 0 20px;
    @media (max-width:500px) {
        padding: 0 10px;
    }
}